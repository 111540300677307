<template>
  <div id="producer-public-temporality">
    <QsCard :qlik-ids="['mvFYj']" />
    <QsCard :qlik-ids="['VYfjPvy']" />
    <QsCard :qlik-ids="['SCZjW']" />

    <QsCard
      :qlik-ids="['EqqGhg', 'PqKyrwp', 'dvHUum']"
      title="Saisonnalité des commandes"
      :date="{ field: 'Jour de la commande', disableFutureDates: true }"
      style="grid-column: 1 / span 3"
      tooltip="Représentation du nombre d'acheteurs en fonction de la date de la commande."
    />
  </div>
</template>

<script>
import QsCard from "@/components/Qlik/QsCard.vue";

export default { name: "ProducerPublicTemporality", components: { QsCard } };
</script>

<style scoped>
#producer-public-temporality {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 300px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 20px;
}
</style>
